const UploadIcon = () => {
  return(
    <svg width="13.002197" height="13.000000" viewBox="0 0 13.0022 13" fill="none" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs/>
      <path id="Vector" d="M12.5 6.12L6.82 11.47C6.13 12.13 5.18 12.5 4.2 12.5C3.22 12.5 2.28 12.13 1.58 11.47C0.89
      10.82 0.5 9.93 0.5 9C0.5 8.07 0.89 7.18 1.58 6.53L7.25 1.18C7.72 0.74 8.35 0.5 9 0.5C9.66 0.5 10.28 0.74 10.75
      1.18C11.21 1.61 11.47 2.21 11.47 2.83C11.47 3.44 11.21 4.04 10.75 4.47L5.07 9.82C4.84 10.04 4.52 10.16 4.19
      10.16C3.87 10.16 3.55 10.04 3.32 9.82C3.09 9.61 2.96 9.31 2.96 9C2.96 8.69 3.09 8.39 3.32 8.18L8.56 3.24"
            stroke="#3D4E5C" strokeOpacity="1.000000" strokeWidth="1.000000" strokeLinejoin="round" strokeLinecap="round"/>
    </svg>
  );
}

export default UploadIcon;