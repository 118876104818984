import styles from './Consultation.module.css';

const Consultation = () => {
  return(
    <div className={styles.container}>
      <div className={styles.panel}>
        <div className={styles.titleBlock}>
          <div className={styles.title}>
            Нужна консультация?
          </div>
          <div className={styles.subtitle}>
            Оставьте заявку – и мы перезвоним.
          </div>
        </div>
        <div className={styles.request}>
          <div className={styles.inputBlock}>
            <input placeholder={'Имя'} className={styles.input}/>
            <input placeholder={'Телефон'} className={styles.input}/>
          </div>
          <div className={styles.button}>Заказать звонок</div>
          <div className={styles.form}>Отправляя форму вы соглашаетесь на обработку персональных данных. </div>
        </div>
      </div>
    </div>
  );
}

export default Consultation;