import styles from './App.module.css';
import Header from "./Header/Header";
import Main from "./Main/Main";
import Services from './Services/Services';
import About from './About/About';
import Consultation from './Consultation/Consultation';
import Calculation from './Calculation/Calculation';
import FAQ from './FAQ/FAQ';
import Footer from './Footer/Footer';
import Customers from './Customers/Customers';
import ConsultationPopup from './common/components/ConsultationPopup/ConsultationPopup';
import {useState} from 'react';
import TenderPopup from './common/components/TenderPopup/TenderPopup';
import CalculationPopup from './common/components/CalculationPopup/CalculationPopup';
import Licenses from './Licenses/Licenses';

const App = () => {
  const [popup, setPopup] = useState(null);

  return (
    <div className={styles.container}>
      {popup === 'consultation' && <ConsultationPopup setPopup={setPopup}/>}
      {popup === 'tender' && <TenderPopup setPopup={setPopup}/>}
      {popup === 'calculation' && <CalculationPopup setPopup={setPopup}/>}
      <Header />
      <div className={styles.content}>
        <Main/>
        <Services setPopup={setPopup}/>
        <About />
        <Licenses />
        <Consultation />
        <Customers />
        <Calculation setPopup={setPopup}/>
        <FAQ />
        <Footer />
      </div>
    </div>
  );
}

export default App;