import styles from './Customers.module.css';
import CustomersMainLine from './CustomersMainLine/CustomersMainLine';
import 'swiper/css';
import {Swiper, SwiperSlide} from 'swiper/react';
import NavigationBlock from '../common/components/NavigationBlock/NavigationBlock';


const Customers = () => {
  const items = [
    {
      img: 'novomet.jpg',
      count: 10
    },
    {
      img: 'planeta.jpg',
      count: 20
    },
    {
      img: 'semya.jpg',
      count: 10
    },
    {
      img: 'sportmaster.jpg',
      count: 10
    },
    {
      img: 'stolitsa.jpg',
      count: 30
    },
    {
      img: 'funday.jpg',
      count: 2
    },
    {
      img: 'ostin.jpg',
      count: 5
    },
    {
      img: 'snowqueen.jpg',
      count: 2
    },
  ]

  return(
    <div className={styles.container} id={'customers'}>
      <div className={styles.title}>
        <div>клиенты</div>
        <CustomersMainLine />
      </div>
      <div className={styles.carousel}>
        <Swiper
          loop={true}
          centeredSlides={true}
          spaceBetween={10}
          slidesPerView={4}
          initialSlide={3}
        >
          {items.map((item) => {
            return(
              <SwiperSlide
                key={item.img}
                className="custom-slide"
                style={{
                  width: '600px',
                  height: '335px',
                  backgroundColor: '#d9d9d9',
                  borderRadius: '16px',
                  transition: 'all 0.3s'
                }}
              >
                <img src={`/img/customers/${item.img}`} alt={'img'} className={styles.img}/>
                <div className={styles.objects}>{`более ${item.count} объектов`}</div>
              </SwiperSlide>
            );
          })}
          <div className={styles.navigation}>
            <NavigationBlock />
          </div>
        </Swiper>
      </div>
    </div>
  );
}

export default Customers;