const AboutLogo = () => {
  return(
    <svg width="14.000000" height="16.000000" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs/>
      <path id="Лого" d="M6.23 7.2C6.33 7.19 6.43 7.14 6.5 7.08L13.8 0.97C14.19 0.64 13.96 0 13.46 0L10.14 0C10.03 0
      9.91 0.04 9.81 0.11L1.51 6.78C1.09 7.12 1.37 7.82 1.91 7.75L6.23 7.2ZM0 1.24C0 0.77 0.55 0.52 0.89 0.84L2.94
      2.77C3.18 3 3.17 3.4 2.91 3.61L0.86 5.25C0.51 5.53 0 5.28 0 4.82L0 1.24ZM1.51 9.21C1.09 8.87 1.37 8.17 1.91
      8.24L6.23 8.79C6.33 8.8 6.43 8.84 6.5 8.91L13.8 15.02C14.19 15.35 13.96 16 13.46 16L10.14 16C10.03 16 9.91
      15.95 9.82 15.88L1.51 9.21ZM0.89 15.15C0.55 15.47 0 15.22 0 14.75L0 11.17C0 10.71 0.51 10.46 0.86 10.74L2.91
      12.38C3.17 12.59 3.18 12.99 2.94 13.22L0.89 15.15Z" fill="#EA6119" fillOpacity="1.000000" fillRule="evenodd"/>
    </svg>
  );
}

export default AboutLogo;