const CostIcon = () => {
  return(
    <svg width="34.000000" height="34.001953" viewBox="0 0 34 34.002" fill="none" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
      <path id="Vector" d="M2.25 15.85L31.69 2.99M26.19 1L31.69 2.99L29.64 8.28M26.6 33L26.6 15.78C26.6 15.45 26.73
      15.14 26.97 14.91C27.21 14.68 27.54 14.55 27.88 14.55L31.72 14.55C32.05 14.55 32.38 14.68 32.62 14.91C32.86
      15.14 33 15.45 33 15.78L33 33L26.6 33ZM13.8 33L13.8 19.47C13.8 19.14 13.93 18.83 14.17 18.6C14.41 18.37 14.74
      18.24 15.08 18.24L18.92 18.24C19.25 18.24 19.58 18.37 19.82 18.6C20.06 18.83 20.2 19.14 20.2 19.47L20.2 33L13.8
      33ZM1 33L1 23.16C1 22.83 1.13 22.52 1.37 22.29C1.61 22.06 1.94 21.93 2.28 21.93L6.12 21.93C6.45 21.93 6.78 22.06
      7.02 22.29C7.26 22.52 7.4 22.83 7.4 23.16L7.4 33L1 33Z"
            stroke="#FFFFFF" strokeOpacity="1.000000" strokeWidth="2.000000" strokeLinejoin="round" strokeLinecap="round"/>
    </svg>
  );
}

export default CostIcon;