const CalculationMainLine = () => {
  return(
    <svg width="1160.000000" height="27.000000" viewBox="0 0 1160 27" fill="none" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <radialGradient gradientTransform="translate(14 13.5) rotate(90) scale(13.5 14)" cx="0.000000" cy="0.000000"
                        r="1.000000" id="paint_radial_33_130_0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4F5F76" stopOpacity="0.501961"/>
          <stop offset="1.000000" stopColor="#999999" stopOpacity="0.000000"/>
        </radialGradient>
      </defs>
      <line id="Line 45" x1="1160.000000" y1="12.500000" x2="13.000000" y2="12.500000" stroke="#4F5F76"
            strokeOpacity="1.000000" strokeWidth="1.000000"/>
      <ellipse id="Ellipse 18" cx="14.000000" cy="13.500000" rx="14.000000" ry="13.500000"
               fill="url(#paint_radial_33_130_0)" fillOpacity="1.000000"/>
    </svg>

  );
}

export default CalculationMainLine;