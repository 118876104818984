import styles from './Service.module.css';
import ServiceMainLine from './ServiceMainLine/ServiceMainLine';
import ServiceAdditionalLine from './ServiceAdditionalLine/ServiceAdditionalLine';

const Services = () => {
  const services = [
    {
      label: 'Проектирование\nсистем\nпожарной защиты',
      imgSrc: 'projecting.jpg'
    },
    {
      label: 'Монтаж\nзапроектированных\nсистем защиты',
      imgSrc: 'installing.jpg'
    },
    {
      label: 'Техническое\nобслуживание\nсистем защиты',
      imgSrc: 'maintenance.jpg'
    },
    {
      label: 'Адаптация систем\nпод архитектурные\nрешения',
      imgSrc: 'adaptation.jpg'
    },
    {
      label: 'Восстановление\nтехнической\nдокументации',
      imgSrc: 'recovery.jpg'
    },
  ]

  const systems = [
    'систему противодымной вентиляции',
    'систему видеонаблюдения',
    'систему контроля и управления доступом'
  ]

  return(
    <div className={styles.container} id={'services'}>
      <div className={styles.title}>
        <div>услуги</div>
        <ServiceMainLine />
      </div>
      <div className={styles.services}>
        {services.map((item) => {
          return(
            <div className={styles.service} key={item.label}>
              <img src={`/img/services/${item.imgSrc}`} alt={'img'} className={styles.serviceImg}/>
              <div className={styles.label}>{item.label}</div>
              <div className={styles.more}>Подробнее</div>
            </div>
          );
        })}
      </div>
      <div className={styles.additional}>
        <div className={styles.info}>
          <div className={styles.additionalTitle}>Дополнительно в рамках комплекса работ мы готовы запроектировать,
            установить и обслуживать:</div>
          {systems.map((item) => {
            return(
              <div className={styles.system} key={item}>
                <ServiceAdditionalLine />
                <div>{item}</div>
              </div>
            )
          })}
        </div>
        <img src={'/img/services/cameras.jpg'} alt={'img'} className={styles.camerasImg}/>
      </div>
    </div>
  );
}

export default Services