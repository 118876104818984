import styles from './ConsultationPopup.module.css';
import {useState} from 'react';
import InputMask from 'react-input-mask';
import {qlistCreate} from '../../../api/api';
import CloseIcon from '../../icons/CloseIcon/CloseIcon';

const ConsultationPopup = ({setPopup}) => {
  const [data, setData] = useState({
    name: '',
    number: '',
    type: 'consultation'
  })

  return(
    <div className={styles.background}>
      <div className={styles.container}>
        <img src={'/img/popups/consultation.jpg'} alt={'consultationImg'} className={styles.img}/>
        <div className={styles.content}>
          <div className={styles.close}>
            <div onClick={() => setPopup(null)}>
              <CloseIcon />
            </div>
          </div>
          <div className={styles.titleBlock}>
            <div className={styles.title}>Получить консультацию</div>
            <div className={styles.subtitle}>Оставьте заявку – и мы перезвоним.</div>
          </div>
          <div className={styles.inputBlock}>
            <input
              className={styles.input}
              onChange={(e) => setData({...data, name: e.target.value})}
              placeholder={'Имя'}
              required
            />
            <InputMask
              onChange={(e) => setData({...data, number: e.target.value})}
              maskChar={null}
              mask="+7 (999) 999 99 99"
              beforeMaskedValueChange={data.number}
              className={styles.input}
              placeholder={'+7 (123) 456 78 90'}
              value={data.number}
              required
            />
          </div>
          <div className={styles.buttonBlock}>
            <div className={styles.button} onClick={() => qlistCreate(data)}>Заказать звонок</div>
            <div className={styles.agreement}>{'Отправляя форму вы соглашаетесь\nна обработку персональных данных.'}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultationPopup;