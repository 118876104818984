import styles from './NavigationBlock.module.css';
import LeftArrow from './LeftArrow/LeftArrow';
import RightArrow from './RightArrow/RightArrow';
import {useSwiper} from 'swiper/react';

const NavigationBlock = () => {
  const swiper = useSwiper();

  const prevSlideHandler = () => {
    swiper.slidePrev();
  }

  const nextSlideHandler = () => {
    swiper.slideNext();
  }

  return(
    <div className={styles.container}>
      <div className={styles.button} onClick={prevSlideHandler}>
        <LeftArrow />
      </div>
      <div className={styles.button} onClick={nextSlideHandler}>
        <RightArrow />
      </div>
    </div>
  )
}

export default NavigationBlock;