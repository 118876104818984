const HeaderLogo = () => {
  return(
    <svg width="52.000000" height="58.000000" viewBox="0 0 52 58" fill="none" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
      <path id="Лого" d="M23.16 26.11C23.53 26.06 23.88 25.91 24.17 25.68L51.26 3.52C52.71 2.34 51.87 0 50.01 0L37.69
       0C37.25 0 36.82 0.14 36.47 0.42L5.63 24.58C4.05 25.82 5.11 28.36 7.1 28.11L23.16 26.11ZM0 4.52C0 2.79 2.05 1.89
       3.32 3.06L10.93 10.07C11.83 10.9 11.78 12.34 10.81 13.09L3.2 19.06C1.9 20.08 0 19.15 0 17.49L0 4.52ZM5.63
       33.42C4.05 32.17 5.1 29.63 7.1 29.88L23.16 31.88C23.53 31.92 23.88 32.07 24.17 32.31L51.26 54.47C52.71 55.65
       51.87 58 50.01 58L37.69 58C37.25 58 36.82 57.85 36.47 57.57L5.63 33.42ZM3.32 54.93C2.05 56.1 0 55.2 0 53.47L0
       40.5C0 38.85 1.9 37.92 3.2 38.94L10.81 44.9C11.77 45.65 11.83 47.09 10.93 47.92L3.32
       54.93Z" fill="#EA6119" fillOpacity="1.000000" fillRule="evenodd"/>
    </svg>

  );
}

export default HeaderLogo;