import styles from './FAQ.module.css'
import FAQMainLine from './FAQMainLine/FAQMainLine';
import Accordion from './Accordion/Accordion';

const FAQ = () => {
  const items = [
    {
      title: 'Какой срок выполнения проектных работ?',
      content: 'От 2 дней в зависимости от количества проектируемых систем, площади объекта и полноты предоставленного ' +
        'комплекта исходных документов'
    },
    {
      title: 'Какой срок монтажа систем пожарной безопасности?',
      content: 'От 1 дня. Наличие в штате компании инженерного и технического персонала плюс наличие складского запаса ' +
        'оборудования и расходных материалов позволяет провести монтаж объектов в данный срок'
    },
    {
      title: 'Есть ли гарантия на работы?',
      content: 'От 12 месяцев'
    },
  ]

  return(
    <div className={styles.container}>
      <div className={styles.title}>
        <div>частые вопросы</div>
        <FAQMainLine />
      </div>
      <div className={styles.content}>
        {items.map(item => <Accordion key={item.title} title={item.title} content={item.content}/>)}
      </div>
    </div>
  );
}

export default FAQ;