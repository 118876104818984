import styles from './Footer.module.css';
import HeaderLogo from '../Header/Icons/Logo/HeaderLogo';
import Arrow from '../Header/Icons/Arrow/Arrow';
import License from '../common/pdfs/License.pdf';
import SRO from '../common/pdfs/SRO.pdf';
import ISO from '../common/pdfs/ISO.pdf';

const Footer = () => {
  const abstracts = [
    'Комплексно',
    'Качественно',
    'Конфиденциально'
  ];

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return(
    <div className={styles.container}>
      <div className={styles.panel}>
        <div className={styles.info}>
          <div className={styles.logoPanel}>
            <HeaderLogo />
            <div className={styles.nameBlock}>
              <div className={styles.name}>Защитный комплекс</div>
              {abstracts.map((item) => {
                return(
                  <div className={styles.abstractBlock} key={item}>
                    <Arrow />
                    <div className={styles.abstract}>{item}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.links}>
            <div onClick={() => scrollToSection('services')} className={styles.linksItem}>Услуги</div>
            <div onClick={() => scrollToSection('about')} className={styles.linksItem}>О компании</div>
            <div onClick={() => scrollToSection('calculation')} className={styles.linksItem}>Расчеты</div>
            <div onClick={() => scrollToSection('customers')} className={styles.linksItem}>Клиенты</div>
          </div>
          <div className={styles.contacts}>
            <div className={styles.phone}>8-800-2002-0030</div>
            <div>fds@zc-59.ru</div>
            <div>г. Пермь, ул. Мира 45А оф. 517</div>
            <div>ПН-ПТ с 09.00 до 17.00</div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.footerItem}>
            <a className={styles.license} href={License} target="_blank" rel="noreferrer">
              Лицензия
            </a>
            <a className={styles.license} href={SRO} target="_blank" rel="noreferrer">
              Выписка
            </a>
            <a className={styles.license} href={ISO} target="_blank" rel="noreferrer">
              Сертификат
            </a>
          </div>
          <div className={styles.footerItem} style={{textDecoration: 'underline'}}>Обработка персональных данных</div>
          <div className={styles.footerItem}>© Защитный Комплекс 2024</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;