import styles from './Main.module.css'
import Roulette from "./Roulette/Roulette";

const Main = () => {
  return(
    <div className={styles.container}>
      <div className={styles.content}>
        <Roulette />
        <img src={"/img/main.jpg"} alt={'img'} className={styles.mainImg}/>
        <div className={styles.slogan}>Под «ключ» | Качественно | С гарантией</div>
        <div className={styles.more}>Узнать больше</div>
      </div>
    </div>
  );
}

export default Main;