const Docs = () => {
  return(
    <svg width="203.675415" height="60.253906" viewBox="0 0 203.675 60.2539" fill="none"
         xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <linearGradient x1="23.230183" y1="26.984344" x2="198.859421" y2="9.484406" id="paint_linear_23_93_0"
                        gradientUnits="userSpaceOnUse">
          <stop offset="0.536523" stopColor="#EA6119"/>
          <stop offset="1.000000" stopColor="#DC0D00"/>
        </linearGradient>
      </defs>
      <g opacity="0.800000">
        <path id="200+" d="M42.05 58.98L0.56 58.98L0.56 50.11L21.28 30.93C26.56 25.85 29.41 22.67 29.41 18.04C29.41
         12.92 25.6 9.74 20.48 9.74C15.17 9.74 11.66 13.16 11.67 18.73L0 18.73C-0.02 7.3 8.35 0 20.56 0C33.04 0
         41.28 7.12 41.28 17.22C41.28 23.84 38.06 29.29 26.11 40.21L17.61 48.53L17.61 48.92L42.05 48.92L42.05
         58.98ZM97.27 29.94C97.25 49.12 88.42 60.25 73.77 60.25C59.12 60.23 50.24 49.06 50.3 29.94C50.31 10.87
         59.18 0 73.77 0C88.36 0 97.31 10.91 97.27 29.94ZM152.33 29.94C152.31 49.12 143.48 60.25 128.83 60.25C114.18
         60.23 105.29 49.06 105.35 29.94C105.37 10.87 114.24 0 128.83 0C143.42 0 152.36 10.91 152.33 29.94ZM188.63
         50.11L178.48 50.11L178.48 35.09L163.44 35.09L163.44 24.92L178.48 24.92L178.48 9.9L188.63 9.9L188.63
         24.92L203.67 24.92L203.67 35.09L188.63 35.09L188.63 50.11ZM62.81 29.94C62.8 43.35 67.11 50.05 73.77 50.05C80.43
         50.05 84.75 43.35 84.71 29.94C84.71 16.73 80.39 10.09 73.77 10.09C67.13 10.09 62.83 16.73 62.81 29.94ZM117.87
         29.94C117.85 43.35 122.17 50.05 128.83 50.05C135.49 50.05 139.81 43.35 139.77 29.94C139.77 16.73 135.45 10.09
         128.83 10.09C122.19 10.09 117.89 16.73 117.87 29.94Z"
              fill="url(#paint_linear_23_93_0)" fillOpacity="1.000000" fillRule="evenodd"/>
      </g>
    </svg>

  );
}

export default Docs;