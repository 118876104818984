import axios from 'axios';

const $api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
});

export const qlistCreate = async (requestParams) => {
  try {
    const response = await $api.post(
      '/landing/createReq',
      requestParams,
      { withCredentials: true }
    );
  } catch (e) {
    console.log(e.response?.data?.message);
  }
};