import styles from './About.module.css';
import AboutMainLine from './svg/AboutMainLine/AboutMainLine';
import Years from './svg/Years/Years';
import Docs from './svg/Docs/Docs';
import CustomersCount from './svg/CustomersCount/CustomersCount';
import Percent from './svg/Percent/Percent';
import AboutLogo from './svg/AboutLogo/AboutLogo';
import AboutAdditionalLine from './svg/AboutAdditionalLine/AboutAdditionalLine';
import Point from './svg/Point/Point';

const About = () => {
  const numbers = [
    {
      icon: <Years />,
      content: 'лет в проектировании,\n монтаже и обслуживании систем\n пожарной защиты'
    },

    {
      icon: <Docs />,
      content: 'разработанных комплектов\n документации на монтаж (адаптацию)\n систем пожарной\n защиты'
    },

    {
      icon: <CustomersCount />,
      content: 'крупных магазинов и ТРК в числе\n клиентов'
    },

    {
      icon: <Percent />,
      content: 'процентов заказчиков стали\n постоянными клиентами'
    },
  ]

  const should = [
    'качественное проектирование в соответствии\n с актуальными нормативами',
    'выполнение монтажа в согласованные сроки\n в соответствии  с рабочей документацией',
    'оформление рабочей и исполнительной документации',
    'поддержание в работоспособном состоянии систем защиты',
    'комплексный подход «под ключ» с гарантией\n ответственности проектировщиков',
  ]

  const shouldnt = [
    'необходимо выполнить монтаж без рабочей документации\n и не планируется её разработка',
    'не важна работоспособность системы, а необходимо только\n наличие «бумажек» об обслуживании',
    'в долгосрочных планах нет поддержания систем\n в работоспособном состоянии',
    'планируется свести к нулю финансовые затраты на обеспечение\n пожарной безопасности объекта.'
  ]

  return(
    <div className={styles.container} id={'about'}>
      <div className={styles.title}>
        <div>о нас</div>
        <AboutMainLine />
      </div>
      <div className={styles.numbers}>
        {numbers.map((item) => {
          return(
            <div className={styles.number} key={item.content}>
              {item.icon}
              <div>{item.content}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.contact}>
        <div className={styles.should}>
          <div className={styles.shouldTitle}>
            <AboutLogo />
            <div>К нам нужно обращаться, если Вам важно:</div>
          </div>
          {should.map((item) => {
            return(
              <div className={styles.factor} key={item}>
                <AboutAdditionalLine />
                <div className={styles.item}>{item}</div>
              </div >
            );
          })}
        </div>
        <div className={styles.shouldnt}>
          <div className={styles.shouldntTitle}>
            К нам НЕ нужно обращаться, если:
          </div>
          {shouldnt.map((item) => {
            return(
              <div className={styles.shouldntFactor} key={item}>
                <Point />
                <div>{item}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default About;