const Percent = () => {
  return(
    <svg width="162.519836" height="62.207031" viewBox="0 0 162.52 62.207" fill="none"
         xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <linearGradient x1="18.204548" y1="28.195282" x2="160.957352" y2="10.695312" id="paint_linear_23_97_0" gradientUnits="userSpaceOnUse">
          <stop offset="0.536523" stopColor="#EA6119"/>
          <stop offset="1.000000" stopColor="#DC0D00"/>
        </linearGradient>
      </defs>
      <g opacity="0.800000">
        <path id="95%" d="M119.82 28.86C111.52 28.86 107.03 22.98 107.03 15.99L107.03 12.92C107.03 5.87 111.7 0 119.82
        0C128.2 0 132.65 5.87 132.65 12.92L132.65 15.99C132.65 23.02 128.08 28.86 119.82 28.86ZM0 21.38C-0.06 9.68 8.71
        1.15 21.71 1.21C33.69 1.26 44.94 8.51 44.96 29.58C45 49.33 35.7 60.99 21.28 60.99C9.64 60.99 1.69 53.57 0.56
        43.76L12.69 43.76C13.63 48 16.83 50.44 21.28 50.44C28.88 50.44 32.89 43.43 32.91 31.89L32.51 31.89C29.94 37.01
        24.25 40.11 17.85 40.11C7.53 40.11 0.01 32.24 0 21.38ZM96.46 40.8C96.5 52.55 87.69 60.99 74.72 60.99C62.69 60.99
        53.9 53.8 53.69 43.75L65.62 43.75C65.89 48.26 69.84 51.32 74.72 51.32C80.5 51.32 84.62 47.14 84.62 41.11C84.62
        35 80.41 30.76 74.57 30.74C71.13 30.74 67.65 32.24 65.87 34.64L54.94 32.69L57.69 2.01L93.26 2.01L93.26
        12.07L67.83 12.07L66.32 26.64L66.66 26.64C68.71 23.76 73.12 21.64 78.22 21.64C88.71 21.64 96.5 29.64 96.46
        40.8ZM118.22 60.19L110.82 60.19L150.82 2.01L158.22 2.01L118.22 60.19ZM119.82 7.26C116.44 7.26 115.35 10.21
        115.35 12.92L115.35 15.99C115.35 18.76 116.46 21.58 119.82 21.58C123.28 21.58 124.33 18.76 124.33 15.99L124.33
        12.92C124.33 10.13 123.39 7.26 119.82 7.26ZM31.69 21.01C31.64 15.48 27.46 10.87 21.81 10.87C16.09 10.87 11.87
        15.37 11.91 21.05C11.95 26.75 16.01 31.19 21.69 31.19C27.32 31.19 31.71 26.58 31.69 21.01ZM149.7 62.2C141.38
        62.2 136.89 56.26 136.89 49.27L136.89 46.21C136.89 39.23 141.5 33.3 149.7 33.3C158.06 33.3 162.52 39.17 162.52
        46.21L162.52 49.27C162.52 56.26 157.95 62.2 149.7 62.2ZM149.7 40.56C146.28 40.56 145.15 43.43 145.15
        46.21L145.15 49.27C145.15 51.99 146.25 54.88 149.7 54.88C153.16 54.88 154.2 52.07 154.2 49.27L154.2 46.21C154.2
        43.43 153.28 40.56 149.7 40.56Z" fill="url(#paint_linear_23_97_0)" fillOpacity="1.000000" fillRule="evenodd"/>
      </g>
    </svg>
  );
}

export default Percent;