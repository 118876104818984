const LeftLine = () => {
  return(
    <svg width="263" height="14" viewBox="0 0 263 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7" cy="7" r="7" transform="matrix(-1 0 0 1 263 0)" fill="url(#paint0_radial_0_1)"/>
      <line y1="-0.5" x2="256" y2="-0.5" transform="matrix(1 0 0 -1 0 7)" stroke="#EA6119"/>
      <defs>
        <radialGradient id="paint0_radial_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(7 7) rotate(90) scale(7)">
          <stop stopColor="#EA6119"/>
          <stop offset="1" stopColor="white" stopOpacity="0.17"/>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default LeftLine;