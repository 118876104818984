import styles from './CalculationPopup.module.css';
import {useState} from 'react';
import InputMask from 'react-input-mask';
import {qlistCreate} from '../../../api/api';
import CloseIcon from '../../icons/CloseIcon/CloseIcon';
import UploadIcon from '../../icons/UploadIcon/UploadIcon';

const CalculationPopup = ({setPopup}) => {
  const [data, setData] = useState({
    name: '',
    number: '',
    email: '',
    comment: '',
    type: 'calculation'
  })

  return(
    <div className={styles.background}>
      <div className={styles.container}>
        <img src={'/img/popups/calculation.jpg'} alt={'tenderImg'} className={styles.img}/>
        <div className={styles.content}>
          <div className={styles.close}>
            <div onClick={() => setPopup(null)}>
              <CloseIcon />
            </div>
          </div>
          <div className={styles.titleBlock}>
            <div className={styles.title}>Расчет стоимости</div>
            <div className={styles.subtitle}>{'Отправьте нам документацию\nи получите готовое КП'}</div>
          </div>
          <div className={styles.inputBlock}>
            <input
              className={styles.input}
              onChange={(e) => setData({...data, name: e.target.value})}
              placeholder={'Имя'}
              required
            />
            <InputMask
              onChange={(e) => setData({...data, number: e.target.value})}
              maskChar={null}
              mask="+7 (999) 999 99 99"
              beforeMaskedValueChange={data.number}
              className={styles.input}
              placeholder={'Телефон'}
              value={data.number}
              required
            />
            <input
              className={styles.input}
              onChange={(e) => setData({...data, name: e.target.value})}
              placeholder={'Email'}
            />
            <textarea
              className={styles.input}
              style={{
                height: '96px',
                paddingTop: '10px'
              }}
              onChange={(e) => setData({...data, name: e.target.value})}
              placeholder={'Комментарий'}
            />
          </div>
          <div className={styles.upload}>
            <UploadIcon />
            <div>Прикрепить_файл</div>
          </div>
          <div className={styles.buttonBlock}>
            <div className={styles.button} onClick={() => qlistCreate(data)}>Отправить заявку</div>
            <div className={styles.agreement}>{'Отправляя форму вы соглашаетесь\nна обработку персональных данных.'}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalculationPopup;