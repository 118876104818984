import styles from './Buttonbar.module.css';
import Rectangle from "../Icons/Rectangle/Rectangle";

const Buttonbar = () => {
  const buttons = [
    {
      title: 'Услуги',
      to: 'services'
    },
    {
      title: 'Расчет',
      to: 'calculation'
    },
    {
      title: 'О нас',
      to: 'about'
    },
    {
      title: 'Клиенты',
      to: 'customers'
    }
  ]

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return(
    <div className={styles.container}>
      {buttons.map((item, index) => {
        return(
          <div
            className={styles.itemBlock}
            onClick={() => scrollToSection(item.to)}
            key={item.title}
          >
            <div className={styles.item}>
              {item.title}
            </div>
            {index !== buttons.length - 1 && <Rectangle />}
          </div>
        )
      })}
    </div>
  );
}

export default Buttonbar;